import React from "react"
import ClaimButton from "./claimButtonPt"

const Claim = () => {
  return (
    <>
      <section className="text-center btn-claim-hldr">
        <ClaimButton />
      </section>
    </>
  )
}

export default Claim
