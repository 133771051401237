import React from "react"
import ChatButton from "../chatBtnPt"
import iconLootLoss from "../../../images/pt/icon-loot-loss-pt.png"

const LootLossTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="loot-loss"
        role="tabpanel"
        aria-labelledby="loot-loss-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-loot-loss.png"
              alt="Loot Loss"
              quality={100}
            /> */}
            <img src={iconLootLoss} alt="Loot Loss" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Perdeu o loot</h3> */}
          <p>
            Você finalmente conseguiu um item lendário, apenas para perdê-lo
            segundos depois porque caiu de um penhasco enquanto comemorava o
            achado? Temos benefícios reais para pessoas que perdem coisas de
            grande valor imaginário, principalmente se for de forma estúpida e
            porque estava com fome.
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Converse com nossa agente agora" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default LootLossTab
