import React from "react"
import ChatButton from "../chatBtnPt"
import iconRageQuit from "../../../images/pt/icon-rage-quit-pt.png"

const RageQuitTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="rage-quit"
        role="tabpanel"
        aria-labelledby="rage-quit-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-rage-quit.png"
              alt="Rage Quit"
              quality={100}
            /> */}
            <img src={iconRageQuit} alt="Rage Quit" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Rage quit</h3> */}
          <p>
            Você detonou algo ou alguém em um chilique incontrolável por causa
            do jogo? Não cobrimos itens quebrados ou danos físicos, mas podemos
            te ajudar a evitar rage quit reforçado pela fome no futuro. E quem
            sabe aprender algumas coisinhas para lidar melhor com a raiva?
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Converse com nossa agente agora" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default RageQuitTab
