import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeContent = () => {
  return (
    <>
      <div className="content pt text-white">
        <div>
          <figure class="mb-3 mb-lg-4">
            <StaticImage
              src="../../images/hero-banner.jpg"
              alt="Hero Banner"
              quality={100}
            />
          </figure>
        </div>
        <div className="page-heading">
          <h1 className="text-uppercase">
            Seus vacilos nos games podem render Snickers na faixa!
          </h1>
        </div>
        <p className="text-md mb-0">
          Se você tem uma gravação mostrando seu vacilo durante um jogo por
          causa da fome, a gente te dá um boost. Chama no Snickers que você pode
          transformar esse seu contratempo no jogo em um delicioso Snickers.
        </p>
      </div>
    </>
  )
}

export default HomeContent
