import React from "react"
import ChatButton from "../chatBtnPt"
import iconRandomBlunders from "../../../images/pt/icon-random-blunders-pt.png"

const OtherBlundersTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="other-blunders"
        role="tabpanel"
        aria-labelledby="other-blunders-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-random-blunders.png"
              alt="Other Blunders"
              quality={100}
            /> */}
            <img
              src={iconRandomBlunders}
              alt="Other Blunders"
              className="img-fluid"
            />
          </figure>
          {/* <h3 className="text-uppercase">Outros vacilos</h3> */}
          <p>
            Você cometeu um erro de jogo porque estava com fome e não é coberto
            por uma de nossas opções? Ou talvez você não consiga descobrir qual
            opção se aplica e cansou de tentar? Nossa agente também pode te
            ajudar com isso!
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Converse com nossa agente agora" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtherBlundersTab
