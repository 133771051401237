import React from "react"
import ChatButton from "../chatBtnPt"
import iconNoobMove from "../../../images/pt/icon-noob-move-pt.png"

const NoobMoveTab = () => {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="noob-move"
        role="tabpanel"
        aria-labelledby="noob-move-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-noob-move.png"
              alt="Noob Move"
              quality={100}
            /> */}
            <img src={iconNoobMove} alt="Noob Move" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Foi noob</h3> */}
          <p>
            Você cometeu um vacilo tão bobo que faria o mais noob dos noobs se
            sentir envergonhado por você? A gente te cobre. Nossos agentes estão
            de prontidão para te ajudar a fazer sua reclamação sem passar muita
            vergonha.
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Converse com nossa agente agora" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoobMoveTab
