import React from "react"

import Layout from "../../components/layoutPt"
import Seo from "../../components/seoPt"
import Landing from "../../components/landing/landingPt"

const IndexPage = () => {
  return (
    <>
      <Layout>
        <Seo
          title="Home | Chama no Snickers"
          lang="pt"
          description="Vacilos causados pela fome podem acontecer enquanto você joga. Felizmente, seus vacilos podem render Snickers na faixa! Conta o que rolou em chamanosnickers.com.br!"
        />
        {/* Campaign is On */}
        <Landing />
        {/* Campaign Closed */}
        {/* <CampaignClosed /> */}
      </Layout>
    </>
  )
}

export default IndexPage
