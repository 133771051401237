import React from "react"
import ChatButton from "../chatBtnPt"
import iconEarlyDeath from "../../../images/pt/icon-early-death-pt.png"

const EarlyDeathTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="early-death"
        role="tabpanel"
        aria-labelledby="early-death-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-early-death.png"
              alt="Early Death"
              quality={100}
            /> */}
            <img src={iconEarlyDeath} alt="Early Death" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Morreu cedo</h3> */}
          <p>
            Você foi o primeiro jogador a morrer em um Battle Royale de cem
            jogadores? O chefe mais fácil te matou antes que você pudesse
            acertar um golpe de leve? Morreu antes mesmo de sua equipe ir para a
            batalha? Nós cobrimos mortes prematuras em videogames de todos os
            tipos. Então transforme sua saída antecipada bem embaraçosa em um
            Snickers!
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Converse com nossa agente agora" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default EarlyDeathTab
